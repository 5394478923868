/* You can add global styles to this file, and also import other style files */
// @import "../node_modules/themify-icons/themify-icons/themify-icons.scss";
// @import url("https://fonts.googleapis.com/icon?family=Material+Icons");
// @import "@angular/material/prebuilt-themes/indigo-pink.css";sss
//$fa-font-path: "../node_modules/font-awesome/fonts";

// @import "~font-awesome/scss/font-awesome.scss";
// @import "assets/scss/icons/themify/themify-icons.css";
// @import "icons/themify/themify-icons.css";
// @import "icons/font-awesome/css/font-awesome.min.css";
// @import "icons/font-awesome/css/fontawesome-all.css";
// @import "icons/themify-icons/themify-icons.css";

.btn {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.img-conf {
  width: 100%;
  height: 200px;
  margin: auto;
  display: block;
}

.card-bottom-btn {
  position: absolute;
  bottom: 0;
  margin: 4px 50px;
}

// .pagination-controls ::ng-deep .ngx-pagination {
//   margin: 10px 10px;
//   li.current {
//     background: #003351 !important;
//     border-radius: 15px;
//   }
// }

.pagination-controls .ngx-pagination {
  margin: 10px 10px;
  li.current {
    background: #003351 !important;
    border-radius: 15px;
  }
}

.card {
  border-radius: 0px !important;
}

.pl4_5 {
  padding-left: 2rem !important;
}

.ng-autocomplete {
  // border: 1px solid black;
  // border-radius: 0px 25px 25px 0px;
  // border-width: 2px;
  // border-style: solid;
  // border-color: black black black transparent;
  width: 273px !important;
  height: 50px;
}

.ng-autocomplete .autocomplete-container{
  box-shadow: 0px 0px !important;
  margin-top: 4px;
}

.ng-autocomplete input{
  // border: 1px solid black;
  border-radius: 25px;
  font-size: 20px;
  // width: 265px;
  height: 50px;
}

.ng-autocomplete .x{
  display: none
}
.search-box-auto-complete{
    width: 325px !important;
    border-radius: 25px;
    height: 50px;
    border: 1px solid black;
}
.search-box-auto-complete .input-group-prepend{
  height: 50px !important;
  border: 1px solid transparent !important;
  border: none !important;
  margin-top: -1px;
  margin-left: -2px;
}

@media only screen and (max-width: 775px) {
  .search-box-auto-complete{
    width: 100% !important;
    border-radius: 25px;
    height: 50px;
    border: 1px solid black;
}
.search-box-auto-complete .input-group-prepend{
  height: 50px !important;
  border: 1px solid transparent !important;
  border: none !important;
  margin-top: -1px;
  margin-left: -2px;
}

.ng-autocomplete {
  // border: 1px solid black;
  // border-radius: 0px 25px 25px 0px;
  // border-width: 2px;
  // border-style: solid;
  // border-color: black black black transparent;
  width: 90% !important;
  height: 50px;
}

}

@media only screen and (max-width: 455px) {
.ng-autocomplete {
  // border: 1px solid black;
  // border-radius: 0px 25px 25px 0px;
  // border-width: 2px;
  // border-style: solid;
  // border-color: black black black transparent;
  width: 88% !important;
  height: 50px;
}
}

@media only screen and (max-width: 400px) {
  .ng-autocomplete {
    // border: 1px solid black;
    // border-radius: 0px 25px 25px 0px;
    // border-width: 2px;
    // border-style: solid;
    // border-color: black black black transparent;
    width: 86% !important;
    height: 50px;
  }
  }