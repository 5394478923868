.rtl {
  direction: rtl;

  input[type="text"],
  input[type="number"],
  input[type="tel"],
  input[type="password"],
  input[type="textarea"] {
    text-align: right;
  }

  input[type="text"]:focus,
  input[type="number"]:focus,
  input[type="tel"]:focus,
  input[type="textarea"]:focus,
  input[type="password"]:focus {
    text-align: right;
  }

  .textAlign {
    text-align: right !important;
  }

  .float-right {
    float: left !important;
  }
  .float-left {
    float: right !important;
  }
  .text-right {
    text-align: left !important;
  }
.text-hebrew{
  text-align: right !important;
}

  [class^="col-"] {
    text-align: right;
  }

  // blog
  .blog-description-text,
  .blogpage-title,
  .blog-subtitle,
  .blog-card-title,
  .card-description {
    text-align: right !important;
  }

  // help

  .help-maintitle,
  .help-subtitle {
    text-align: right !important;
  }

  // Meet Exert
  .meetexperts-subtitle,
  .meetexperts-title,
  .meetexperts-card-title,
  .expert-detail-title,
  .expert-detail-subtitle,
  .expert-description-text,
  .expert-descrtiption-title,
  .expert-description-text {
    text-align: right !important;
  }

  // Order
  .order-history-title {
    text-align: right !important;
  }

  // profile

  .profile-title {
    text-align: right !important;
  }

  // Proposal

  .proposal-title {
    text-align: right !important;
  }

  //
  .navbar-nav > li {
    padding-left: 18px !important;
    // padding-right: 0px !important;
  }

  .navbar-nav{
    padding-right: 0px;
  }

.rtl-pr-4{
  padding-right: 2.1rem;
}

  // project
  .project-title {
    text-align: right !important;
  }
  .project-subtitle {
    text-align: right !important;
  }
  .project-card-title {
    text-align: right !important;
  }
  .checkout-subtitle,
  .checkout-title,
  .cart-summary-title {
    text-align: right !important;
  }
  .cal-details {
    text-align: right !important;
  }
  .wishlist-subtitle,
  .wishlist-summary-title {
    text-align: right !important;
  }
  .proposal-summary-title,
  .proposal-subtitle {
    text-align: right !important;
  }

  //

  .zoom-viewer {
    right: 321px !important;
  }

  // Login
  .login-title {
    text-align: right !important;
  }

  .form-group input:checked + label:after {
    right: 9px !important;
  }

  .dropdown-menu-right {
    right: 0;
    left: auto;
  }

  .select-list:after {
    position: absolute;
    right: 70%;
  }

  @media (max-width: 575px) {
    .select-list:after {
      position: absolute;
      right: 78%;
    }
  }
  

  .select-list select {
    direction: rtl;
  }
  .add-cart {
    margin-left: 10px;
  }

  .search-box {
    text-align: right !important;
  }

  @media only screen and (min-width: 767px) {
    .mainmenu .collapse ul ul ul {
      position: absolute;
      top: 0;
      left: auto;
      min-width: 200px;
      display: none;
      right: 199px;
    }
  }

  @media only screen and (min-width: 767px) {
    .mainmenu .collapse ul ul li {
      text-align: right;
    }
  }
  .menu-item--level2 a span::after {
    right: inherit;
    left: 0 !important;
    -webkit-transform: rotate(-50%deg) translate(-50%, 0%) !important;
    transform: rotate(-50deg) translate(-50%, 0%) !important;
  }

  .item-filter-graybox-title {
    text-align: right !important;
  }

  .icon-toggle > a:after {
    right: inherit !important;
    left: 0 !important;
  }
  .filter-showall {
    margin: 0px 42px;
  }
}
