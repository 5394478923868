@charset "utf-8";

/* CSS Document */
@font-face {
  font-family: MyriadHebrew;
  src: url(../scss/icons/myriad-hebrew.otf);
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Rubik:400,700&display=swap");

@font-face {
  font-family: Montserrat-Regular;
  src: url("../scss/icons/montserrat/Montserrat-ExtraLight.ttf");
}

@font-face {
  font-family: Montserrat-Regular;
  src: url("../scss/icons/montserrat/Montserrat-Light.ttf");
}

@font-face {
  font-family: Montserrat-Regular;
  src: url("../scss/icons/montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: Montserrat-Medium;
  src: url("../scss/icons/montserrat/Montserrat-Medium.ttf");
}

@font-face {
  font-family: Montserrat-Bold;
  src: url("../scss/icons/montserrat/Montserrat-Bold.ttf");
}

@font-face {
  font-family: Montserrat-ExtraBold;
  src: url("../scss/icons/montserrat/Montserrat-ExtraBold.ttf");
}

@font-face {
  font-family: Montserrat-Italic;
  src: url("../scss/icons/montserrat/Montserrat-Italic.ttf");
}

@font-face {
  font-family: Montserrat-Black;
  src: url("../scss/icons/montserrat/Montserrat-Black.ttf");
}

// .rtl {
//   @font-face {
//     font-family: 'Rubik', sans-serif;
//     src: url("../scss/icons/Rubik/Rubik-Regular.ttf");
//   }
// }

// @import "icons/themify/themify-icons.css";
// @import "icons/font-awesome/css/font-awesome.min.css";
// @import "icons/font-awesome/css/fontawesome-all.css";
// @import "icons/themify-icons/themify-icons.css";
body,
html {
  height: 100%;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 100%;
}

a {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

@import "./rtl.scss";
a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #e65540;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

.font-montserrat-regular {
  font-family: "Rubik", sans-serif !important;
}

.font-montserrat-extralight {
  font-family: "Rubik", sans-serif !important;
}

.font-montserrat-light {
  font-family: "Rubik", sans-serif !important;
}

.font-montserrat-semi-bold {
  font-family: "Rubik", sans-serif !important;
}

.font-montserrat-bold {
  font-family: "Rubik", sans-serif !important;
}

.font-montserrat-ExtraBold {
  font-family: "Rubik", sans-serif !important;
  font-weight: 800 !important;
}

.font-montserrat-black {
  font-family: "Rubik", sans-serif !important;
  letter-spacing: 0.5px;
}

.font14 {
  font-size: 14px !important;
}

.f-weight-bold {
  font-weight: 900 !important;
}

.font-size14 {
  font-family: "Rubik", sans-serif;
  font-size: 14px !important;
}

.font-size18 {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
}

.cart-icon {
  font-size: 21px;
}

p {
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  line-height: 1.7;
  color: #888888;
  margin: 0px;
}

.text-style-1 {
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

.text-style-2 {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}

.text-style-3 {
  font-family: MyriadHebrew;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.59;
  color: #04b6f0;
}

.text-style-4 {
  font-family: "Rubik", sans-serif;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.59;
  color: #04b6f0;
}

.text-style-5 {
  font-family: MyriadHebrew;
  font-size: 13px;
  font-weight: bold;
  line-height: 2.08;
  color: #9d9d9d;
}

.text-style-6 {
  font-family: "Rubik", sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 2.08;
  color: #9d9d9d;
}

.add-to-cart-btn-box {
  width: 167px;
  height: 47px;
  background-color: #fecd08;
  border: 1px solid #fecd08;
  border-radius: 23px;
}

.add-to-cart-btn-box:hover {
  border: 2px solid #fecd08;
  color: #fecd08;
  background-color: transparent;
}

.add-to-cart-btn {
  width: 194px;
  height: 54px;
  border-radius: 26.7px;
  background-color: #fecd08;
  border: 1px solid #fecd08;
  font-weight: 600;
}
.add-to-cart-btn:hover {
  border: 2px solid #fecd08;
  color: #fecd08;
  background-color: transparent;
}

.product-image {
  width: 245px;
  height: 245px;
  padding: 10px;
  margin: 3px;
}
.product-image img {
}

.product-img {
  width: 100%;
  height: 230px;
}

.icon-cart {
  width: 17px;
  height: 17px;
}
a:hover .icon-cart {
  fill: #fecd08;
}

button:hover .icon-cart {
  fill: #fecd08;
}

.quick-view-btn {
  width: 46px;
  height: 46px;
  border-radius: 100px;
  background-color: #000;
  border: #000;
  color: #fff;
}

.quick-view-btn:hover {
  background-color: transparent;
  border: 2px solid #000;
  color: #000;
}

.product-detail-heading-style-1 {
  font-family: "Rubik", sans-serif;
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
}

.product-detail-price-style-1 {
  font-family: "MyriadHebrew";
  font-size: 24px;
  font-weight: bold;
  line-height: 1.6;
  color: #04b6f0;
}

.product-detail-price-style-2 {
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.6;
  color: #04b6f0;
}

.product-detail-price-style-3 {
  font-family: "MyriadHebrew";
  font-size: 18.5px;
  font-weight: bold;
  line-height: 2.08;
  color: #9d9d9d;
}

.product-detail-price-style-4 {
  font-family: "Rubik", sans-serif;
  font-size: 18.5px;
  font-weight: normal;
  line-height: 2.08;
  color: #9d9d9d;
}

.product-detail-age-range-style-1 {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  line-height: 2.21;
  direction: initial;
}

.product-detail-description-style-1 {
  font-family: "Rubik", sans-serif;
  font-size: 15.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: right;
  color: #000000 !important;
}

.Add-to-wish-proposal-list-text {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000 !important;
}

.recommended-product-title {
  font-family: "Rubik", sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000000 !important;
}
.recommended-product-subtitle-1 {
  font-family: "Rubik", sans-serif;
  font-size: 15.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: right;
  color: #000000 !important;
}
.recommended-product-subtitle-2 {
  font-family: MyriadHebrew;
  font-size: 18.5px;
  font-weight: bold;
  line-height: 2.08;
  color: #04b6f0;
}
.recommended-product-subtitle-3 {
  font-family: Rubik;
  font-size: 18.5px;
  font-weight: bold;
  line-height: 2.08;
  color: #04b6f0;
}

.cart-product-title {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000000 !important;
}
.cart-product-title:hover {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000000 !important;
}

.cart-product-price-icon {
  font-family: MyriadHebrew;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.24;
  color: #04b6f0;
}
.cart-product-price-blue {
  font-size: 20px;
  font-weight: bold;
  line-height: 2.24;
  color: #04b6f0;
}
.cart-item-qty {
  font-size: 17.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.49;
  letter-spacing: normal;
  text-align: center;
  color: #000000 !important;
  vertical-align: -webkit-baseline-middle;
}

.cart-deleted-price {
  font-size: 13px;
  font-family: MyriadHebrew;
  font-weight: bold;
  color: #9d9d9d;
}
.text-red-trash {
  color: #ed1c24 !important;
  line-height: 36px;
}

.cart-product-thumbnail {
  width: 87px;
  height: 70px;
}
.cart-product-thumbnail img {
  width: auto;
  height: 70px;
}

.cart-icons {
  vertical-align: middle;
  color: #00aeef;
}
.bg-graybox {
  background-color: rgba(209, 209, 209, 0.4);
}

.total-amount-box {
  height:auto;
  background-color: rgba(209, 209, 209, 0.4);
  bottom: 0px;
  position: relative;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.cart-total-text {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.91;
  letter-spacing: normal;
  text-align: left;
  color: #000000 !important;
}
.cart-total-amount {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.91;
  letter-spacing: normal;
  text-align: right;
  color: #000000 !important;
}

.login-btn {
  /*width: 361px;*/
  width: 90% !important; /*Added By Ashwin - 6-6-2022*/
  height: 51px;
  border-radius: 25px;
  background-color: #fecd08;
  border: 1px solid #fecd08;

  font-family: "Rubik", sans-serif;
  font-size: 18.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #000000 !important;
  text-transform: uppercase;
}

.login-btn:hover {
  color: #fecd08;
  border: 1px solid #fecd08;
  background-color: transparent;
}

.register-btn {
  /*width: 361px;*/
  width: 90% !important; /*Added By Ashwin - 6-6-2022*/
  height: 51px;
  border-radius: 25px;
  background-color: #e6e6e6;
  border: 1px solid #e6e6e6;

  font-family: "Rubik", sans-serif;
  font-size: 18.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #000000 !important;
  text-transform: uppercase;
}

.register-btn:hover {
  color: #000;
  border: 1px solid #e6e6e6;
  background-color: transparent;
}

.password-field {
  width: 361px;
  height: 51px;
  border-radius: 25px;
  border: solid 1px #d7d7d7;
  background-color: #ffffff;
  padding-left: 10px;
}

.username-field {
  width: 100%;
  height: 51px;
  border-radius: 25px;
  border: solid 1px #d7d7d7;
  background-color: #ffffff;
  padding-left: 10px;
}
.password-field::placeholder {
  color: #000 !important;
}
.username-field::placeholder {
  color: #000 !important;
}

.close-btn {
  font-family: "Rubik", sans-serif;
  font-size: 26px !important;
  font-weight: 300px !important;
  color: #fff !important;
}

.view-chkout-btn {
  font-family: "Rubik", sans-serif;
  width: 299px;
  height: 46px;
  border-radius: 23px;
  background-color: #fecd08;
  border: 1px solid #fecd08;
  color: #000;
  font-weight: bold;
}

.view-chkout-btn:hover {
  border: 1px solid #fecd08;
  background-color: #fff;
  color: #fecd08;
}

.proposal-quote-btn {
  font-family: "Rubik", sans-serif;
  width: 299px;
  height: 46px;
  border-radius: 23px;
  background-color: #ededed;
  border: 1px solid #ededed;
  color: #000;
  font-weight: bold;
}
.proposal-quote-btn:hover {
  border: 1px solid #ededed;
  background-color: #fff;
}

.chkbox label {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.61;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.chkbox label::before {
  top: 1px;
}

.login-title {
  font-family: "Rubik", sans-serif;
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.font-bold-600 {
  font-weight: 600 !important;
}

.card-360 {
  width: 360px;
}

.card-263 {
  width: 263px;
}

.featured-card-263 {
  width: 263px;
  height: 263px;
}

.border-gray-dark {
  border: 1px solid #d7d7d7 !important;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

.modal-content {
  background-color: transparent !important;
  border: none !important;
}

.social-circle {
  width: 40px;
  height: 40px;
  border: 2px solid #00aeef;
  border-radius: 10rem;
  padding: 7px 13px 0px 12px;
  margin: 4px;
  color:#00aeef;
}


.social-circle:hover {
  background-color: #00aeef;
  transition: all 0.5s;
  color: #fff;
}

.social-circle a.fb {
  margin: 0.4rem;
  color: #00aeef; /* #04b6f0;*/
}

.social-circle:hover a.fb {
  color: #fff;
}

.social-circle a.yt {
  margin: 0.3rem;
  color: #00aeef; /* #04b6f0;*/
}

.social-circle:hover a.yt {
  color: #fff;
}

.social-circle a.ig {
  margin: 0.3rem;
  color: #00aeef; /* #04b6f0;*/
}

.social-circle:hover a.ig {
  color: #fff;
}

.social-circle a.tw {
  margin: 0.2rem;
  color: #00aeef; /* #04b6f0;*/
}

.social-circle:hover a.tw {
  color: #fff;
}

.footer-bg {
  background-image: url(../images/footer_bg1.png);
  background-position: center;
  background-size: cover;
}

.ml-50 {
  margin-left: 50%;
}

.pt-2_5 {
  padding-top: 2.5rem;
}

.pt-1_9 {
  padding-top: 1.9rem;
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  margin-left: 5px;
}

.text-uppercase {
  text-transform: uppercase;
}

.total {
  position: relative;
  right: 100px;
}

.item_count span {
  color: #fff;
  font-weight: bolder;
}

/*Icon Toggle*/
.icon-toggle > a {
  display: block;
  position: relative;
}

.icon-toggle > a:after {
  content: "\f067";
  /*plus */
  font-family: "FontAwesome";
  position: absolute;
  right: 0;
}

.icon-toggle > a[aria-expanded="true"]:after {
  content: "\f068";
  /* minus */
}

/*svg icons*/
.icon .i-15 {
  width: 15px;
  height: 15px;
  color: #04b6f0;
}

.fill-blue {
  fill: #04b6f0 !important;
}

/*hidden-block*/
.hidden-block {
  visibility: hidden;
}

/*pagination*/
.dot {
  height: 35px;
  width: 35px;
  // background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 5px;
}

.dot a {
  padding: 14px;
  top: 6px;
  position: relative;
}

/*cart Popup*/

.cart-popup {
 // position: absolute !important;
 // z-index: 0;
  //left: -55px !important;
  background-color: #fcfcfc;
  box-shadow: 1px 2px 12px silver;
}

@media only screen and (max-width: 767px) {
  .cart-popup {
  //  position: absolute !important;
   // z-index: 100;
    left: -275px !important;
    background-color: #fcfcfc;
    box-shadow: 1px 2px 12px silver;
  }
}

// .cart-popup .tip::before {
//   width: 0;
//   height: 0;
//   content: "";
//   border-top: 0;
//   border-right: 1em solid transparent;
//   border-left: 1em solid transparent;
//   border-bottom: 1em solid #fff;
//   position:absolute;
//   top:-17px;
//   left: 40px;

// }

.cart-popup .arrow-up:before,
.cart-popup .arrow-up:after {
  // content: " ";
  // height: 0;
  // position: absolute;
  // width: 0;
  // border: 25px solid transparent;
  /* arrow size */
}

.cart-popup .arrow-up:before {
  // border-bottom-color: #fff;  __<<ngThemingMigrationEscapedComment13>>__

  /* positioning */
  // position: absolute;
  // top: -50px;
  // left: 10px;
  // z-index: 2;
}

.cart-popup .arrow-up:after {
  // border-bottom-color: #333;  __<<ngThemingMigrationEscapedComment15>>__
  /* positioning */
  // position: absolute;
  // top: -24px;
  // left: 255px;
  // z-index: 1;
}

// .arrow-up {
//   width: 0;
//   height: 0;
//   content: "";
//   border-top: 0;
//   border-right: 1em solid transparent;
//   border-left: 1em solid transparent;
//   border-bottom: 1em solid #fff;
//   position: absolute;
//   top: -16px;
//   left: 280px; //320
// }

/*navbar*/
.direction-ltr {
  direction: ltr !important;
}
.dropdown-menu {
  padding: 0 !important;
  margin: 0 !important;
  border: 0 solid transition !important;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.mainmenu a,
.navbar-default .navbar-nav > li > a,
.mainmenu ul li a,
.navbar-expand-lg .navbar-nav .nav-link {
  color: #000;
  text-transform: capitalize;
  padding: 10px 7px;
  display: block !important;
  text-decoration: none;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.mainmenu .active a,
.mainmenu .active a:focus,
.mainmenu .active a:hover,
.mainmenu li a:hover,
.mainmenu li a:focus,
.navbar-default .navbar-nav > .show > a,
.navbar-default .navbar-nav > .show > a:focus,
.navbar-default .navbar-nav > .show > a:hover {
  color: #77dbfd;

  outline: 0;
}

/*==========Sub Menu=v==========*/

.mainmenu .collapse ul ul,
.mainmenu .collapse ul ul.dropdown-menu {
  background: #fff;
  box-shadow: 1px 2px 12px #999 !important;
}

.mainmenu .collapse ul ul ul,
.mainmenu .collapse ul ul ul.dropdown-menu {
  background: #fff;
}

.mainmenu .collapse ul ul ul ul,
.mainmenu .collapse ul ul ul ul.dropdown-menu {
  background: #fff;
}

/******************************Drop-down menu work on hover**********************************/
.mainmenu {
  background: none;
  border: 0 solid;
  margin: 0;
  padding: 0;
  min-height: 20px;
  width: 100%;
}

@media only screen and (min-width: 767px) {
  .mainmenu .collapse ul li:hover > ul {
    display: block;
  }

  .mainmenu .collapse ul ul {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 200px;
    display: none;
  }

  /*******/
  .mainmenu .collapse ul ul li {
    position: relative;
  }

  .mainmenu .collapse ul ul li:hover > ul {
    display: block;
  }

  .mainmenu .collapse ul ul ul {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 200px;
    display: none;
  }

  /*******/
  .mainmenu .collapse ul ul ul li {
    position: relative;
  }

  .mainmenu .collapse ul ul ul li:hover ul {
    display: block;
  }

  .mainmenu .collapse ul ul ul ul {
    position: absolute;
    top: 0;
    right: -100%;
    min-width: 200px;
    display: none;
    z-index: 1;
  }
}

@media only screen and (max-width: 767px) {
  .navbar-nav .show .dropdown-menu .dropdown-menu > li > a {
    padding: 16px 15px 16px 35px;
  }

  .navbar-nav .show .dropdown-menu .dropdown-menu .dropdown-menu > li > a {
    padding: 16px 15px 16px 45px;
  }
}

@media only screen and (max-width: 767px) {
  .text-sm {
    font-size: 70% !important;
  }

  .text-s {
    font-size: 60% !important;
  }
}

.text-md {
  font-size: 80% !important;
}

/* -/navbar */

/*checkbox style*/

.checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
}

.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #555555;
}

.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
  opacity: 0;
  z-index: 1;
}

.checkbox input[type="checkbox"]:focus + label::before,
.checkbox input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="radio"]:checked + label::after {
  font-family: "FontAwesome";
  content: "\f00c";
}

.checkbox input[type="checkbox"]:disabled + label,
.checkbox input[type="radio"]:disabled + label {
  opacity: 0.65;
}

.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox input[type="radio"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #337ab7;
  border-color: #337ab7;
}

.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff;
}

.checkbox-danger input[type="checkbox"]:checked + label::before,
.checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f;
}

.checkbox-danger input[type="checkbox"]:checked + label::after,
.checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff;
}

.checkbox-info input[type="checkbox"]:checked + label::before,
.checkbox-info input[type="radio"]:checked + label::before {
  background-color: #04b6f0;
  border-color: #04b6f0;
}

.checkbox-info input[type="checkbox"]:checked + label::after,
.checkbox-info input[type="radio"]:checked + label::after {
  color: #fff;
}

.checkbox-warning input[type="checkbox"]:checked + label::before,
.checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.checkbox-warning input[type="checkbox"]:checked + label::after,
.checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff;
}

.checkbox-success input[type="checkbox"]:checked + label::before,
.checkbox-success input[type="radio"]:checked + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.checkbox-success input[type="checkbox"]:checked + label::after,
.checkbox-success input[type="radio"]:checked + label::after {
  color: #fff;
}

.checkbox.checkbox-sm label::before {
  width: 30px;
  height: 30px;
  top: -13px;
}

.checkbox.checkbox-sm label::after {
  width: 30px;
  height: 30px;
  padding-left: 4px;
  font-size: 20px;
  left: 1px;
  top: -13px;
}

.checkbox.checkbox-sm label {
  padding-left: 18px;
  top: 13px;
}

.checkbox.checkbox-md label::before {
  width: 34px;
  height: 34px;
  top: -17px;
}

.checkbox.checkbox-md label::after {
  width: 34px;
  height: 34px;
  padding-left: 4px;
  font-size: 24px;
  left: 1px;
  top: -18px;
}

.checkbox.checkbox-md label {
  padding-left: 22px;
  top: 17px;
}

.checkbox.checkbox-lg label::before {
  width: 46px;
  height: 46px;
  top: -28px;
}

.checkbox.checkbox-lg label::after {
  width: 46px;
  height: 46px;
  padding-left: 4px;
  font-size: 36px;
  left: 1px;
  top: -31px;
}

.checkbox.checkbox-lg label {
  padding-left: 34px;
  top: 32px;
}

.radio {
  padding-left: 20px;
}

.radio label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
}

.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}

.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 11px;
  height: 11px;
  left: 3px;
  top: 3px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #555555;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio input[type="radio"] {
  opacity: 0;
  z-index: 1;
}

.radio input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}

.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}

.radio.radio-inline {
  margin-top: 0;
}

.radio-primary input[type="radio"] + label::after {
  background-color: #337ab7;
}

.radio-primary input[type="radio"]:checked + label::before {
  border-color: #337ab7;
}

.radio-primary input[type="radio"]:checked + label::after {
  background-color: #337ab7;
}

.radio-danger input[type="radio"] + label::after {
  background-color: #d9534f;
}

.radio-danger input[type="radio"]:checked + label::before {
  border-color: #d9534f;
}

.radio-danger input[type="radio"]:checked + label::after {
  background-color: #d9534f;
}

.radio-info input[type="radio"] + label::after {
  background-color: #5bc0de;
}

.radio-info input[type="radio"]:checked + label::before {
  border-color: #5bc0de;
}

.radio-info input[type="radio"]:checked + label::after {
  background-color: #5bc0de;
}

.radio-warning input[type="radio"] + label::after {
  background-color: #f0ad4e;
}

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #f0ad4e;
}

.radio-warning input[type="radio"]:checked + label::after {
  background-color: #f0ad4e;
}

.radio-success input[type="radio"] + label::after {
  background-color: #5cb85c;
}

.radio-success input[type="radio"]:checked + label::before {
  border-color: #5cb85c;
}

.radio-success input[type="radio"]:checked + label::after {
  background-color: #5cb85c;
}

input[type="checkbox"].styled:checked + label:after,
input[type="radio"].styled:checked + label:after {
  font-family: "FontAwesome";
  content: "\f00c";
}

input[type="checkbox"] .styled:checked + label::before,
input[type="radio"] .styled:checked + label::before {
  color: #fff;
}

input[type="checkbox"] .styled:checked + label::after,
input[type="radio"] .styled:checked + label::after {
  color: #fff;
}

.radio.radio-sm label::before {
  width: 30px;
  height: 30px;
  top: -13px;
}

.radio.radio-sm label::after {
  width: 22px;
  height: 22px;
  padding-left: 4px;
  font-size: 20px;
  left: 4px;
  top: -9px;
}

.radio.radio-sm label {
  padding-left: 18px;
  top: 13px;
}

.radio.radio-md label::before {
  width: 34px;
  height: 34px;
  top: -17px;
}

.radio.radio-md label::after {
  width: 26px;
  height: 26px;
  padding-left: 4px;
  font-size: 24px;
  left: 4px;
  top: -13px;
}

.radio.radio-md label {
  padding-left: 22px;
  top: 17px;
}

.radio.radio-lg label::before {
  width: 46px;
  height: 46px;
  top: -28px;
}

.radio.radio-lg label::after {
  width: 36px;
  height: 36px;
  padding-left: 4px;
  font-size: 36px;
  left: 5px;
  top: -23px;
}

.radio.radio-lg label {
  padding-left: 34px;
  top: 32px;
}

/*range Slider*/

.slidecontainer {
  width: 100%;
  /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  width: 100%;
  /* Full-width */
  height: 25px;
  /* Specified height */
  background: #d3d3d3;
  /* Grey background */
  outline: none;
  /* Remove outline */
  opacity: 0.7;
  /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s;
  /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1;
  /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 25px;
  /* Set a specific slider handle width */
  height: 25px;
  /* Slider handle height */
  background: #4caf50;
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px;
  /* Set a specific slider handle width */
  height: 25px;
  /* Slider handle height */
  background: #4caf50;
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
}

/*Product Details*/

.preview {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media screen and (max-width: 996px) {
  .preview {
    margin-bottom: 20px;
  }
}

.preview-pic {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.preview-thumbnail.nav-tabs {
  border: none;
  margin-top: 15px;
}

.preview-thumbnail.nav-tabs li {
  // width: 18%;
  margin-right: 2.5%;
}

.preview-thumbnail.nav-tabs li img {
  max-width: 100%;
  display: block;
}

.preview-thumbnail.nav-tabs li a {
  padding: 0;
  margin: 0;
}

.preview-thumbnail.nav-tabs li:last-of-type {
  margin-right: 0;
}

.table td,
.table th {
  vertical-align: inherit !important;
}

/*hr text*/
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}

.hr-text:before {
  content: "";
  /* use the linear-gradient for the fading effect*/
  /* use a solid background color for a solid bar*/
  background: #9d9d9d;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;

  padding: 0 0.5em;
  line-height: 1.5em;
  /* this is really the only tricky part, you need to specify the background color of the container element...*/
  color: #000;
  background-color: #fcfcfa;
}

a {
  text-decoration: none !important;
}

#subscription-email ::placeholder {
  color: #fff !important;
  font-weight: 500;
  font-size: 15px;
  font-family: Rubik-medium;
  top: -4px;
  position: relative;
}

.redbox-text {
  font-family: Rubik, sans-serif;
  font-size: 21.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
}

.border-white2x {
  border: 2px solid #fff !important;
}

.bg-red {
  background-color: #ed1c24;
}

.bg-lightblue {
  color: #fff !important;
  background-color: #04b6f0 !important;
  border-color: #04b6f0 !important;
}

.btn-checkout {
  height: 45px;
  padding-top: 10px !important;
  font-weight: bolder !important;
  font-size: 16px !important;
  border-radius: 3.5rem !important;
  margin-top: 32px;
  letter-spacing: 0px;
  width: 136px;
}

.btn-dark {
  color: #fff !important;
}

.btn-black {
  color: #fff !important;
  background-color: #000 !important;
  border: 1px solid #000 !important;
}

.btn-black:hover {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000;
}

.btn-warning:hover {
  background-color: #fff !important;
  color: #fecd08 !important;
  border-color: #fecd08 !important;
}

.btn-primary {
  background-color: #00aeef !important;
}

.btn-primary:hover {
  background-color: #fff !important;
  color: #00aeef !important;
  border-color: #00aeef !important;
}

.btn-danger {
  background-color: #ed1c24 !important;
}

.btn-danger:hover {
  background-color: #fff !important;
  color: #ed1c24 !important;
  border-color: #ed1c24 !important;
}

.btn-dark:hover {
  background-color: #fff !important;
  color: #003351 !important;
  border-color: #fff;
}

.btn-lightblue:hover {
  background-color: #fff !important;
  color: #04b6f0 !important;
  border-color: #04b6f0;
}

.btn-success:hover {
  background-color: #fff !important;
  color: green !important;
}

.btn-secondary:hover {
  background-color: #fff !important;
  color: #555555 !important;
}

.btn-darkblue:hover {
  background-color: #fff !important;
  color: #003351 !important;
  border-color: #003351 !important;
}

.btn-light {
  color: red !important;
}

.btn-light .redbtn {
  color: #000;
}

.btn-submit {
  border: 2px solid #fff !important;
  width: 104px;
  height: 46px;
}

.btn-light:hover {
  background-color: transparent !important;
  color: #fff !important;
}

.btn-light .redbtn {
  color: red;
}

.btn-warning {
  background-color: #fecd08 !important;
}

.linkedin-profile-btn {
  width: 182px;
  height: 46px;
  border-radius: 23px;
  background-color: #003351;
  border: 1px solid #003351;
  font-family: Rubik;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.linkedin-profile-btn:hover {
  border: 2px solid #003351;
  background-color: transparent;
  color: #003351;
}

// .search-box-container {
//   width: 262px !important;
// }

.search-box {
  height: 46px !important;
  border: solid 1px #003351 !important;
  border-top-right-radius: 100px !important;
  border-bottom-right-radius: 100px !important;
}

.search-box-prepend {
  border-top-left-radius: 100px !important;
  border-bottom-left-radius: 100px !important;
}

.border-darkblue {
  border: solid 1px #003351 !important;
}

.search-box::placeholder {
  font-family: Montserrat-medium;
  font-size: 12px;
  position: relative;
  top: -3px;
  color: #000 !important;
}

.icon-color {
  color: #1a4d6d;
}

.btn-lightblue {
  color: #fff !important;
  background-color: #04b6f0 !important;
  border-color: #04b6f0 !important;
}

.btn-darkblue {
  color: #fff !important;
  background-color: #003351 !important;
  border-color: #003351 !important;
}

.text-lightblue {
  color: #04b6f0 !important;
}

.text-white {
  color: #fff;
}

.text-white2 {
  color: rgb(230, 230, 230);
}

.text-dark-blue {
  color: #3f6a84;
}

.text-dark-blue:hover {
  color: #19191a;
  font-weight: bolder;
}

.text-darkblue {
  color: #003352;
}

.bg-darkblue {
  background-color: #003352;
}

.text-light-gray {
  color: rgba(161, 161, 161, 0.8) !important;
}
.text-black {
  color: #000 !important;
}
.text-dark-gray {
  color: #818078;
}

.text-gray-light {
  color: #999999 !important;
}

.bg-gray-light {
  background-color: #ededed;
}
.bg-gray-dark {
  background-color: #d1d1d1;
}

.a-btn {
  text-decoration: none !important;
}

.form-rounded {
  border-radius: 1.5rem !important;
  -webkit-border-radius: 1.5rem !important;
  height: 46px !important;
}

.form-rounded:focus {
  border-color: #ced4da !important;
  outline: none !important;
}

.btn-rounded {
  border-radius: 1.5rem !important;
}

.btn-height46 {
  height: 46px;
}

// .raidus-lt-lb {
//   border-top-left-radius: 100px !important;
//   border-bottom-left-radius: 100px !important;
// }

.underline-2 {
  border-bottom: 1px solid red;
  padding-bottom: 5px;
}

.form-control {
  direction: initial;
}

.form-control:focus {
  box-shadow: none;
}

.underline-1:hover {
  text-decoration: none !important;
  font-weight: bolder;
  border-bottom: 5px solid #04b6f0;
  padding-bottom: 5px;
  color: #04b6f0 !important;
}

.active-link {
  text-decoration: none !important;
  font-weight: bolder;
  border-bottom: 5px solid #04b6f0;
  padding-bottom: 5px;
  color: #04b6f0 !important;
}

// .input-group-text {
//   border-radius: 1.25rem;
//   padding: 0rem 0.75rem !important;
//   border-top-right-radius: 100px !important;
//   border-bottom-right-radius: 100px !important;
// }

// .input-group {
//   direction: initial;
// }

.form-control:focus {
  border-color: none;
}

.navbar {
  padding: 0.5rem 0rem !important;
}

.navbar-border {
  border-bottom: 3px solid #04b6f0;
  border-top: 3px solid #04b6f0;
}

.navbar-border-1 {
  border-bottom: 1px solid #a8a8a8;
  border-top: 1px solid #a8a8a8;
}

.navbar-nav > li {
  // padding-left: 12px;
  // padding-right: 17px;
  padding-left: 1px;
  padding-right: 20px;
}

.navbar-default {
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  background-color: transparent;
  border-left: none;
  border-right: none;
}

.height250 {
  height: 250px;
}

.mt-195 {
  margin-top: 195px;
}

.img-bg1 {
  background-image: url(../images/bg1.png);
  background-position: inherit;
  background-size: cover;
}

@media only screen and (max-width: 767px) {
  .img-bg1 {
    background-image: url(../images/750_strip.png);
    background-position: inherit;
    background-size: cover;
  }
}

.img-bg2 {
  background-image: url(../images/com-cen.png);
  background-position: inherit;
  background-size: cover;
}

.img-bg3 {
  background-image: url(../images/gift-ideas.png);
  background-position: inherit;
  background-size: cover;
}

.img-bg4 {
  background-image: url(../images/our-range.png);
  background-position: inherit;
  background-size: cover;
}

.img-bg5 {
  background-image: url(../images/meet-our-experts.png);
  background-position: inherit;
  background-size: cover;
}

.img-bg6-750 {
  background-image: url(../images/750_strip.png);
  background-position: inherit;
  background-size: cover;
}

/*Parnter Slider*/
.slick-slide {
  margin: 0px 20px;
}

.slick-slide img {
  width: 100%;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.select-list select {
  -webkit-appearance: none;
}

.select-list:after {
  content: "▼";
  position: absolute;
  top: 5px;
  right: 10px;
  bottom: 0;
  font-size: 60%;
  line-height: 30px;
  color: #555;
  pointer-events: none;
  padding: 2px 25px;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*SlideNavbar*/

.navbar-light .navbar-toggler {
  border: none;
}

@media (max-width: 992px) {
  .navbar-collapse {
    position: absolute;
    top: 54px;
    right: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    width: 100%;
    transition: all 0.3s ease;
    display: block;
  }

  .navbar-collapse.collapsing {
    height: auto !important;
    margin-right: 50%;
    transition: all 0.3s ease;
    display: block;
  }

  .navbar-collapse.show {
    right: 0;
  }
}

/*
    DEMO STYLE
*/

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

/*placeholder*/

.fa-1x {
  font-size: 20px;
}

// a:hover {
//   font-weight: bold;
// }

.line-through {
  text-decoration: line-through;
  color: #6c757dad;
}

.cart-for-quotation {
  font-size: 14px;
}

/* ---------------------------------------------------
  SIDEBAR STYLE
----------------------------------------------------- */

// .sidebar {
//   width: 250px;
//   position: fixed;
//   top: 0;
//   left: -250px;
//   height: 100vh;
//   z-index: 999;
//   background: rgb(255, 255, 255);
//   color: rgb(63, 62, 62);
//   transition: all 0.3s;
//   overflow-y: scroll;
//   box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
// }

// .sidebar.active {
//   left: 0;
// }

// .dismiss {
//   width: 35px;
//   height: 35px;
//   line-height: 35px;
//   text-align: center;
//   background: #04b6f0;
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   cursor: pointer;
//   -webkit-transition: all 0.3s;
//   -o-transition: all 0.3s;
//   transition: all 0.3s;
// }

// .dismiss:hover {
//   background: #fff;
//   color: #04b6f0;
// }

// .overlay {
//   display: none;
//   position: fixed;
//   width: 100vw;
//   height: 100vh;
//   background: rgba(0, 0, 0, 0.7);
//   z-index: 998;
//   opacity: 0;
//   transition: all 0.5s ease-in-out;
// }
// .overlay.active {
//   display: block;
//   opacity: 1;
// }

// .sidebar .sidebar-header {
//   padding: 20px;
//   background: #fff;
// }

// .sidebar ul.components {
//   padding: 20px 0;
//   border-bottom: 1px solid #47748b;
// }

// .sidebar ul p {
//   color: #fff;
//   padding: 10px;
// }

// .sidebar ul li a {
//   padding: 10px;
//   font-size: 1.1em;
//   display: block;
// }

// .sidebar ul li a:hover {
//   // color: #04b6f0;
//   background: #fff;
// }

// .sidebar ul li.active > a,
// a[aria-expanded="true"] {
//   color: #fff;
//   // background: #04b6f0;
// }

// a[data-toggle="collapse"] {
//   position: relative;
// }

// .dropdown-toggle::after {
//   display: block;
//   position: absolute;
//   top: 50%;
//   right: 20px;
//   transform: translateY(-50%);
// }

// ul ul a {
//   font-size: 0.9em !important;
//   padding-left: 30px !important;
//   background: #04b6f0;
// }

// ul.CTAs {
//   padding: 20px;
// }

// ul.CTAs a {
//   text-align: center;
//   font-size: 0.9em !important;
//   display: block;
//   border-radius: 5px;
//   margin-bottom: 5px;
// }

/* ---------------------------------------------------
  CONTENT STYLE
----------------------------------------------------- */

#content {
  min-height: 100vh;
  transition: all 0.3s;
}

.textLeft {
  text-align: left !important;
}
